// extracted by mini-css-extract-plugin
export var column = "Toggle__column__i1naB";
export var control = "Toggle__control__Qd_Kp";
export var disabled = "Toggle__disabled__BQUgp";
export var flex = "Toggle__flex__hUdYj";
export var flexColumn = "Toggle__flexColumn__Xmo2q";
export var gap1 = "Toggle__gap1__eC5Fs";
export var gap2 = "Toggle__gap2__nn6gB";
export var gap3 = "Toggle__gap3__EpkBg";
export var gap4 = "Toggle__gap4__B58eJ";
export var gap5 = "Toggle__gap5__A7H9d";
export var lg = "Toggle__lg__Mu6Se";
export var md = "Toggle__md__NXr1V";
export var off = "Toggle__off__fRXXo";
export var on = "Toggle__on__ozxHd";
export var row = "Toggle__row__XAwRu";
export var sm = "Toggle__sm__DKpy9";
export var toggle = "Toggle__toggle__yoiyo";