// extracted by mini-css-extract-plugin
export var barContainer = "ResourceDashboardNavbar__barContainer__ynZdc";
export var barWrapper = "ResourceDashboardNavbar__barWrapper__SsY7I";
export var column = "ResourceDashboardNavbar__column__SJGfE";
export var flex = "ResourceDashboardNavbar__flex__ZlQJd";
export var flexColumn = "ResourceDashboardNavbar__flexColumn__Rqxjm";
export var gap1 = "ResourceDashboardNavbar__gap1__ngs5_";
export var gap2 = "ResourceDashboardNavbar__gap2__PbVa4";
export var gap3 = "ResourceDashboardNavbar__gap3__NYaGW";
export var gap4 = "ResourceDashboardNavbar__gap4__qkXfq";
export var gap5 = "ResourceDashboardNavbar__gap5__s8ViC";
export var linkContainer = "ResourceDashboardNavbar__linkContainer__Zvoov";
export var linkItem = "ResourceDashboardNavbar__linkItem__cQ9Gx";
export var row = "ResourceDashboardNavbar__row__yErLX";
export var underlinedLink = "ResourceDashboardNavbar__underlinedLink__Jxllv";