// extracted by mini-css-extract-plugin
export var column = "ResourcesOptions__column__z4hA_";
export var flex = "ResourcesOptions__flex__dwacA";
export var flexColumn = "ResourcesOptions__flexColumn__eKsGg";
export var gap1 = "ResourcesOptions__gap1__Y_m6s";
export var gap2 = "ResourcesOptions__gap2__eRFUq";
export var gap3 = "ResourcesOptions__gap3__tGe9U";
export var gap4 = "ResourcesOptions__gap4__i1fyO";
export var gap5 = "ResourcesOptions__gap5__xoCfL";
export var popoverBody = "ResourcesOptions__popoverBody__zrPDW";
export var popoverBodyLink = "ResourcesOptions__popoverBodyLink__SYmNk";
export var popoverBodyLinkIcon = "ResourcesOptions__popoverBodyLinkIcon__g3F5U";
export var row = "ResourcesOptions__row__aNO00";